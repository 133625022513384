import {
    Box,
    Divider,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
} from "@mui/material";
import useHasSomePermission from "@repo/shared/hooks/useHasSomePermission.ts";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

export const drawerWidth = 240;

export type NavigationItem = {
    label: string;
    icon: ReactNode;
    href: string;
    permissions: string[];
};

type DrawerContentProps = {
    navigationItems: NavigationItem[];
    onClose: () => void;
    footer?: ReactNode;
};

const DrawerContent = ({ navigationItems, onClose, footer }: DrawerContentProps): ReactNode => {
    const hasSomePermission = useHasSomePermission();

    return (
        <>
            <Toolbar />
            <Divider />
            <List>
                {navigationItems.map(
                    (item) =>
                        hasSomePermission(item.permissions) && (
                            <ListItemButton
                                key={item.href}
                                component={Link}
                                to={item.href}
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.label} />
                            </ListItemButton>
                        ),
                )}
            </List>

            {footer && (
                <>
                    <Divider />
                    <Box sx={{ p: 2 }}>{footer}</Box>
                </>
            )}
        </>
    );
};

type DrawerContainerProps = {
    navigationItems: NavigationItem[];
    mobileOpen: boolean;
    onDrawerClose: () => void;
    onDrawerTransitionEnd: () => void;
    footer?: ReactNode;
};

const DrawerContainer = ({
    navigationItems,
    mobileOpen,
    onDrawerClose,
    onDrawerTransitionEnd,
    footer,
}: DrawerContainerProps): ReactNode => {
    const drawerContent = (
        <DrawerContent navigationItems={navigationItems} onClose={onDrawerClose} footer={footer} />
    );

    return (
        <Box component="nav" sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}>
            <Drawer
                variant="temporary"
                open={mobileOpen}
                onTransitionEnd={onDrawerTransitionEnd}
                onClose={onDrawerClose}
                ModalProps={{
                    keepMounted: true,
                }}
                sx={{
                    display: { xs: "block", md: "none" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
            >
                {drawerContent}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: "none", md: "block" },
                    "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
                }}
                open
            >
                {drawerContent}
            </Drawer>
        </Box>
    );
};

export default DrawerContainer;
